@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Ubuntu", Arial, Helvetica, sans-serif;
    }

    body {
        @apply bg-[#FBFBFF] text-gray-700 antialiased;
    }

    h1,
    h2,
    h3 {
        @apply text-primary;
    }

    a {
        @apply text-primary transition hover:text-primary-light;
    }
}

@layer components {
    .required label {
        @apply after:text-red-500 after:content-["*"];
    }

    .btn {
        @apply inline-flex w-fit min-w-[180px] items-center justify-center whitespace-nowrap rounded-lg px-4 py-2 text-lg leading-[initial] no-underline shadow-xl transition disabled:cursor-no-drop disabled:opacity-60 [&>span&svg]:ml-2 [&>svg]:mr-2 [&>svg]:text-2xl;
    }

    .btn-primary {
        @apply btn border border-cta bg-cta text-alternative-black hover:enabled:bg-[#FEFCED] hover:enabled:text-cta;
    }

    .btn-secondary {
        @apply btn bg-cta-alternative text-white hover:enabled:bg-alternative-black;
    }

    .btn-secondary-old {
        @apply btn bg-dark-secondary text-white hover:enabled:bg-secondary-light hover:enabled:text-dark-secondary;
    }

    .btn-alternative {
        @apply btn border border-primary bg-white text-primary hover:enabled:border-secondary-light hover:enabled:bg-secondary-light;
    }

    .btn-page-header {
        @apply btn bg-cta text-alternative-black hover:bg-[#FFDA9F] hover:text-alternative-black;
    }

    .btn-page-header-secondary {
        @apply btn bg-cta-alternative text-alternative-white hover:bg-[#BBC5D1] hover:text-dark-secondary;
    }

    .form-control {
        @apply sm:grid sm:grid-cols-3 sm:items-start sm:gap-8;
    }

    .form-label {
        @apply flex h-10 items-center text-lg sm:col-span-1;
    }

    .form-input {
        @apply block h-10 w-full rounded-none border-b bg-transparent transition read-only:opacity-60 focus:bg-none focus-visible:outline-none focus-visible:outline-offset-2 sm:text-sm;
    }

    .form-input:not(.error) {
        @apply border-gray-300 focus:border-primary focus:ring-primary;
    }

    .form-input.error {
        @apply border-red-300 focus:border-red-400 focus:ring-red-400;
    }

    .form-checkbox-container,
    .form-radio-container {
        @apply flex h-10 items-center;
    }

    .form-checkbox-container input,
    .form-radio-container input {
        @apply h-4 w-4;
    }

    .form-checkbox-container label,
    .form-radio-container label {
        @apply ml-2;
    }

    .form-textarea {
        @apply block h-24 w-full rounded border bg-transparent p-4 transition focus:ring-primary sm:text-sm;
    }

    .login-form-control {
        @apply relative mb-[20px] flex items-center text-white [&>input:focus]:placeholder:text-transparent [&>input]:h-[45px] [&>input]:w-[350px] [&>input]:border-b [&>input]:border-[#fffbfb] [&>input]:bg-transparent [&>input]:text-white [&>input]:placeholder:text-white [&>input]:placeholder:transition [&>input]:placeholder:duration-500 [&>input]:placeholder:ease-in-out [&>svg]:absolute [&>svg]:right-0 [&>svg]:cursor-pointer;
    }

    .form-select .css-13cymwt-control,
    .form-select .css-t3ipsp-control {
        background: transparent;
        min-height: 40px;
        @apply border-gray-300;
        border: none;
        border-bottom: 1px solid #d1d5db;
        border-radius: 0px;
        outline: none;
        box-shadow: none;
    }

    .form-select .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .form-select.error .css-13cymwt-control,
    .form-select.error .css-t3ipsp-control {
        @apply border-red-400;
    }

    .form-select .css-1fdsijx-ValueContainer {
        padding-left: 0px;
    }
    .form-select .css-1xc3v61-indicatorContainer {
        color: #000 !important;
        font-size: 1.25rem !important;
    }

    .form-select .css-t3ipsp-control:hover {
        border-color: #d1d5db !important;
    }

    .form-select .css-qbdosj-Input input {
        background: 0px center !important;
    }

    .message-grid {
        @apply grid gap-5;
        grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
        grid-template-rows: repeat(3, 210px);
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: #bbbbbb;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

*::-webkit-scrollbar-track {
    background: #f3f3f3;
}

*::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 20px;
}

.ql-container p {
    font-size: 14px;
}
